import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { RepeatOffender } from '../model';

@Injectable()
export class RepeatOffenderApi {
  protected basePath = `${environment.API_V4}/admin/repeat-offender`;

  constructor(protected http: HttpClient) {}

  public repeatOffendersReport() {
    const path = `${this.basePath}/repeat-offenders-report`;
    return this.http.get<Array<string[]>>(path);
  }

  public removeFromList(profileId: string): Observable<{success: boolean}> {
    const path = `${this.basePath}/remove-from-list`;
    return this.http.post<{success: boolean}>(
      path,
      { profileId, globalDeletion: true },
    );
  }

  public getRepeatOffenders(): Observable<RepeatOffender[]> {
    const path = `${this.basePath}/global-repeat-offenders`;
    return this.http.get<RepeatOffender[]>(path);
  }

  public addRepeatOffender(repeatOffender: RepeatOffender): Observable<RepeatOffender> {
    const path = `${this.basePath}/add-to-list`;
    return this.http.post<RepeatOffender>(path, repeatOffender);
  }
}
